//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import blok from '~/mixins/blok'
export default {
  mixins: [blok],
  computed: {
    bgClass () {
      switch (this.blok.variant) {
        case 'light-blue':
          return 'tw-bg-blue-50'
        default:
          return 'tw-bg-blue-600'
      }
    },
    textClass () {
      switch (this.blok.variant) {
        case 'light-blue':
          return 'tw-text-blue-600'
        default:
          return 'tw-text-white'
      }
    },
    numberClass () {
      switch (this.blok.variant) {
        case 'light-blue':
          return 'tw-text-blue-600'
        default:
          return 'tw-text-white tw-border-white tw-border-opacity-[17%] tw-border-b-4'
      }
    },
    captionClass () {
      switch (this.blok.variant) {
        case 'light-blue':
          return 'tw-text-blue-500'
        default:
          return 'tw-font-light tw-opacity-70'
      }
    }
  }
}
